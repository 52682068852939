import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './assets/css/general.css';
import AppRoutes from './App.routes';

const App: React.FC = () => {
  return (
    <div className="">
      <AppRoutes />
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default App;
