import React, { lazy } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import PageSpinner from './components/PageSpinner';
import { AppRoutings } from './utility/enums/app-routings';
// import { Authenticator } from 'aws-amplify-react';
import { useAppSelector } from './store/app.hooks';
import { authSelector } from './store/auth/auth.reducer';
import { configurationSelector } from './store/configuration/configuration.reducer';
import { KEY_LS_TARGET_URL, KEY_Q_CID, KEY_Q_CONFIG, KEY_Q_TARGET } from './utility/constants';
import { isNavigationRestricted, parseConfigParameter } from './utility/constants/customisation';

interface IRoute {
  path: string;
  component: React.LazyExoticComponent<React.FC<{}>> | React.ComponentType<{}>;
  isProtectedRoute: boolean;
}

const rentalRoutes: IRoute[] = [
  {
    path: AppRoutings.SignIn,
    component: lazy(() => import('./pages/SignIn')),
    isProtectedRoute: false,
  },
  {
    path: AppRoutings.SSO,
    component: lazy(() => import('./pages/SignIn/index-sso')),
    isProtectedRoute: false,
  },
  {
    path: AppRoutings.SSOlogin,
    component: lazy(() => import('./pages/SignIn/index-sso-login')),
    isProtectedRoute: false,
  },
  {
    path: AppRoutings.PublicImages,
    component: lazy(() => import('./pages/DamageDetails')),
    isProtectedRoute: false,
  },
  {
    path: AppRoutings.PublicImagesOld,
    component: lazy(() => import('./pages/DamageDetails')),
    isProtectedRoute: false,
  },
  {
    path: AppRoutings.PublicImagesMigration,
    component: lazy(() => import('./pages/DamageDetails')),
    isProtectedRoute: false,
  },
  {
    path: AppRoutings.PublicDamages,
    component: lazy(() => import('./pages/DamageCloseupReview')),
    isProtectedRoute: false,
  },
  {
    path: AppRoutings.CarDealerValuationCarOwnerFirstOffer,
    component: lazy(() => import('./pages/CarDealer/Valuation/CarOwner/FirstOffer')),
    isProtectedRoute: false,
  },
  {
    path: AppRoutings.Reviews,
    component: lazy(() => import('./pages/Reviews')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.NewPolicies,
    component: lazy(() => import('./pages/NewPolicies')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.NewClaims,
    component: lazy(() => import('./pages/NewClaims')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Locations,
    component: lazy(() => import('./pages/Locations')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Assets,
    component: lazy(() => import('./pages/Assets')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.AssetDetails,
    component: lazy(() => import('./pages/AssetDetails')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.AssetCondition,
    component: lazy(() => import('./pages/Rental/Asset/AssetCondition')),
    isProtectedRoute: true,
  },
  /*
  {
    path: AppRoutings.AssetEditDetails,
    component: lazy(() => import('./pages/AssetDetails/AssetEditDetails')),
    isProtectedRoute: true,
  },
  */
  {
    path: AppRoutings.DamageDetails,
    component: lazy(() => import('./pages/DamageDetails')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.DamageOverview,
    component: lazy(() => import('./pages/DamageCloseupReview')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.DashboardRental,
    component: lazy(() => import('./pages/DashboardRental')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.RequestScan,
    component: lazy(() => import('./pages/RequestScan')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Scans,
    component: lazy(() => import('./pages/Scans')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.ScansDetails,
    component: lazy(() => import('./pages/ScansDetails')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Gantry,
    component: lazy(() => import('./pages/Gantry')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Settings,
    component: lazy(() => import('./pages/Settings')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Admin,
    component: lazy(() => import('./pages/Admin')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Users,
    component: lazy(() => import('./pages/Admin/Users')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.PageNotFound,
    component: lazy(() => import('./pages/PageNotFound')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.BatchUpload,
    component: lazy(() => import('./pages/BatchUpload')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.WorkshopFinder,
    component: lazy(() => import('./pages/WorkshopFinder')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.WorkshopUpload,
    component: lazy(() => import('./pages/WorkshopUpload')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Workshops,
    component: lazy(() => import('./pages/Workshops')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.CaptureCheck,
    component: lazy(() => import('./pages/CaptureCheck')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.CaptureCheckDetails,
    component: lazy(() => import('./pages/CaptureCheckDetails')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Claims,
    component: lazy(() => import('./pages/Claims')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.ClaimsDetails,
    component: lazy(() => import('./pages/ClaimsDetails')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.LabelGroup,
    component: lazy(() => import('./pages/ClaimsLabelGroup')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.SmartAssessment,
    component: lazy(() => import('./pages/ClaimsSmartAssessment')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.SmartAssessmentNext,
    component: lazy(() => import('./pages/ClaimsSmartAssessment/NextPage')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Scoreboard,
    component: lazy(() => import('./pages/Expo/Scoreboard')),
    isProtectedRoute: true,
  },

  {
    path: AppRoutings.ScanComparisonTemp,
    component: lazy(() => import('./pages/Scans/ScansComparison/Overview')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Comparisons,
    component: lazy(() => import('./pages/Scans/Comparisons')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.ScanComparisonDetails,
    component: lazy(() => import('./pages/Scans/ScansComparison/Overview')),
    isProtectedRoute: true,
  },
];

const claimsRoutes: IRoute[] = [
  {
    path: AppRoutings.SignIn,
    component: lazy(() => import('./pages/SignIn')),
    isProtectedRoute: false,
  },
  {
    path: AppRoutings.DashboardClaims,
    component: lazy(() => import('./pages/DashboardClaims')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.Claims,
    component: lazy(() => import('./pages/Claims')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.LabelGroup,
    component: lazy(() => import('./pages/ClaimsLabelGroup')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.SmartAssessment,
    component: lazy(() => import('./pages/ClaimsSmartAssessment')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.SmartAssessmentNext,
    component: lazy(() => import('./pages/ClaimsSmartAssessment/NextPage')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.RequestForRepairer,
    component: lazy(() => import('./pages/ClaimsViewForRepairer')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.CreateQuote,
    component: lazy(() => import('./pages/CreateQuote')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.ReviewQuote,
    component: lazy(() => import('./pages/ClaimsReviewQuote')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.RepairRequests,
    component: lazy(() => import('./pages/RepairRequests')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.RequestView,
    component: lazy(() => import('./pages/ClaimsRequestView')),
    isProtectedRoute: true,
  },
  {
    path: AppRoutings.PageNotFound,
    component: lazy(() => import('./pages/PageNotFound')),
    isProtectedRoute: true,
  },
];

const AppRoutes: React.FC = () => {
  const authUser = useAppSelector(authSelector);
  const appConfig = useAppSelector(configurationSelector);
  const location = useLocation();

  const configParameter = new URLSearchParams(useLocation().search).get(KEY_Q_CONFIG);
  parseConfigParameter(configParameter);

  const getWorkflow = () => {
    // TODO: we're merging claims into rental
    return rentalRoutes;
    /*
    return appConfig.configuration.workflow && appConfig.configuration.workflow === 'claims'
      ? claimsRoutes
      : rentalRoutes;
      */
  };

  const getTarget = () => {
    console.log('getTarget in');
    let targetURLRental = AppRoutings.DashboardRental.toString();
    if (location.pathname == AppRoutings.SignIn) {
      const existing = localStorage.getItem(KEY_LS_TARGET_URL);
      console.log('getTarget existing', existing);
      localStorage.removeItem(KEY_LS_TARGET_URL);
      if (existing != null && existing.length > 0) {
        targetURLRental = '/' + existing;
      } else {
        const q = new URLSearchParams(useLocation().search).get(KEY_Q_TARGET);
        if (q != null && q.length > 0) {
          targetURLRental = '/' + q;
        }
      }
    }
    console.log('getTarget out', targetURLRental);
    return appConfig.configuration.workflow && appConfig.configuration.workflow === 'claims'
      ? AppRoutings.DashboardClaims
      : targetURLRental;
  };

  const getSearchParameter = () => {
    let target = '';
    const q = new URLSearchParams(useLocation().search).get(KEY_Q_TARGET);
    if (q != null && q.length > 0) {
      localStorage.setItem(KEY_LS_TARGET_URL, q);
      target = '?target=' + localStorage.getItem(KEY_LS_TARGET_URL);
    }
    const qCID = new URLSearchParams(useLocation().search).get(KEY_Q_CID);
    if (qCID != null && qCID.length > 0) {
      target += target.length == 0 ? '?' : '&';
      target += 'cid=' + qCID;
    }
    console.log('Search param crratin', target);
    return target;
  };

  return (
    <React.Suspense fallback={<PageSpinner />}>
      <>
        {console.log('App route for', window.location.href)}
        {!authUser.isAuthenticated ? (
          <>
            <Routes>
              {getWorkflow()
                .filter((route) => !route.isProtectedRoute)
                .map(({ path, component }, key) => (
                  <Route path={path} Component={component} key={key} />
                ))}
              <Route
                path={AppRoutings.SpamURLS}
                element={
                  <Navigate
                    to={{
                      pathname: AppRoutings.SignIn,
                      search: getSearchParameter(),
                    }}
                  />
                }
              ></Route>
            </Routes>
          </>
        ) : (
          <>
            <MainLayout side={!isNavigationRestricted()}>
              <Routes>
                {/* SignIn needs to come before the getWorkflow() as it has a different route there. */}
                <Route path={AppRoutings.SignIn} element={<Navigate to={getTarget()} />}></Route>

                <Route
                  path={AppRoutings.CarDealerValuationCarOwnerFirstOffer}
                  element={"lazy(() => import('./pages/CarDealer/Valuation/CarOwner/FirstOffer')"}
                ></Route>

                {getWorkflow()
                  .filter((route) => route.isProtectedRoute || !route.isProtectedRoute)
                  .map(({ path, component }, key) => (
                    <Route path={path} Component={component} key={key} />
                  ))}

                {/* SignIn needs to come before the getWorkflow() as it has a different route there. */}
                {[AppRoutings.WebApp, AppRoutings.WebRoot].map((path) => (
                  <Route key={path} path={path} element={<Navigate to={getTarget()} />}></Route>
                ))}

                <Route
                  path={AppRoutings.SpamURLS}
                  element={
                    <Navigate
                      to={{
                        pathname: AppRoutings.PageNotFound,
                      }}
                    />
                  }
                ></Route>
              </Routes>
            </MainLayout>
          </>
        )}
      </>
    </React.Suspense>
  );
};

// From: https://reactrouter.com/en/main/start/faq#what-happened-to-withrouter-i-need-it
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter(AppRoutes);
4;
