import Amplify, { PubSub } from 'aws-amplify';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './assets/css/main.css';
import PageSpinner from './components/PageSpinner';
import './i18n/config';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store';
import { KEY_LS_AWS_CONFIG } from './utility/constants';
import { getAWSConfig } from './utility/constants/server_details';
import React from 'react';

/*
 * Inspired by https://stackoverflow.com/a/52184642 and https://stackoverflow.com/a/56458070
 * A JSON file with the cloud target is imported. The contents of the file will be server dependent.
 * DO NOT COMMIT THE BUILD JSON FILE IN GIT! It's the server's responsibility to provide this file after build.
 * The /public/config.js file looks like this:
 * {
 *   "cloudenv": "2",
 *    "account": "210750284236"
 * }
 * where the stringified number corresponds to the configuration keys in server_details.SERVER_CONFIG
 */
declare global {
  // Just so that TypeScript doesn't get upset
  interface Window {
    app: any;
  }
}
const awsConfigJson = window.app && window.app.env;
localStorage.setItem(KEY_LS_AWS_CONFIG, JSON.stringify(awsConfigJson));
Amplify.configure(getAWSConfig(awsConfigJson));
PubSub.configure(getAWSConfig(awsConfigJson));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Suspense fallback={<PageSpinner />}>
          <App />
        </Suspense>
      </Router>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
